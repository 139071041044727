@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../../assets/fonts/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../../../assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../../assets/fonts/Montserrat-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../../../assets/fonts/Montserrat-Medium.otf");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("../../../../assets/fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("../../../../assets/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSans-Italic";
  src: url("../../../../assets/fonts/OpenSans-Italic.ttf");
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("../../../../assets/fonts/OpenSans-Light.ttf");
}

.mainImage {
  background-image: url("../../../../assets/3.jpg");
  height: 600px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
}
.mainImage1 {
  background-image: url("../../../../assets/2.jpg");
  height: 600px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
}
.mainImage2 {
  background-image: url("../../../../assets/1.jpg");
  height: 600px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
}
.heading {
  width: 70%;
}

.heading h1 {
  font-size: 60px;
  font-family: "OpenSans-Bold";
  color: #fff;
  letter-spacing: 0.35px;
}
.heading span {
  display: block;
  border: 1px solid #4a4a4a;
  border-radius: 22.5px;
  max-width: 450px;
  margin-top: 7%;
  padding: 2.5% 2.5% 2.5% 2.5%;
  padding-right: 0;
}
.heading p {
  color: #fff;
  padding-left: 5%;
  text-transform: capitalize;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: left;
  font-family: "OpenSans-Regular";
  font-size: 18px;
}

.innerContentHeading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.heroWrapper {
  min-height: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 81%, 0 100%, 0 99%, 0% 60%, 0 0));
  clip-path: polygon(50% 0%, 100% 0, 100% 81%, 0 100%, 0 99%, 0% 60%, 0 0); */
  height: 100%;
  /* background: rgb(55, 56, 56);
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(15, 15, 15, 1) 100%); */

  background-image: url("../../../../assets/background-home.jpg");
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.heroWrapperInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loadedWrapper {
  background: url("../../../../assets/images/hero-banner/HeroLanding.jpeg") no-repeat center !important;
  background-size: cover !important;
}
.description {
  line-height: 43px;
  width: 80%;
  font-size: 22px;
  margin: 50px 0;
  margin-top: 0px;
  font-family: "Montserrat-Regular";
  letter-spacing: 0.34px;
}
.suggestionImage {
  width: 130px;
  height: 40px;
  object-fit: contain;
  margin: 0 5px;
  letter-spacing: 0.34px;
}

.smallScreenButton {
  display: none;
}

.innerContent {
  display: flex;
  min-height: 318px;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 55px;
}

@media (max-width: 1024px) {
  .textContent h1 {
    width: 100%;
  }
  .description {
    width: 100%;
  }
  .contentWrapper {
    margin: 0 auto;
  }
}

.textContent h1:last-child {
  font-family: "Montserrat-Bold";
  letter-spacing: 0.28px;
}
a {
  cursor: pointer;
}
.images {
  justify-content: center;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  right: 14%;
  z-index: 30;
  bottom: 6%;
}
.imagesContainer {
  display: flex;
  flex-direction: row;
  float: right;
}
.images img {
  width: 100%;
}
.regularPhone img {
  /* width:280px; */
  width: calc(100% + 20px);
  position: relative;
  right: 40px;
  bottom: 10px;
}
.largestPhone img {
  /* width:calc(100% + 40px); */
  max-width: 395px;
  max-height: 750px;
  min-width: 395px;
  min-height: 750px;
  object-fit: contain;
  position: relative;
  border-radius: 60px;
}
.contentWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
@media only screen and (max-width: 1050px) {
  .bigScreenButton {
    display: none !important;
  }
  .smallScreenButton {
    display: initial;
  }
  .innerContent {
    min-height: unset;
  }
  .heroWrapper h1 {
    font-size: 1.5em;
  }
  .content {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 10px;
    flex: 1;
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .button {
    position: relative;
  }
  .images {
    position: relative;
    left: 60px;
    flex: 0;
  }
  .imagesContainer > div {
    width: 200px;
  }
  .regularPhone img {
    right: 80px;
  }
  .largestPhone img {
    right: 146px;
  }
  .textContent {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    order: 2;
  }
  .textContent h1 {
    margin-bottom: 15px;
  }
  .btnContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (max-width: 678px) {
  .content {
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 0px;
  }
  .images {
    flex: 0;
  }
  .heroWrapper {
    padding-top: 0px;
  }
  .innerContent {
    padding-bottom: 10px;
  }
  .textContent {
    min-height: unset;
  }
  .imagesContainer {
    display: none;
  }
  .mobileImage {
    display: initial;
  }
  .images {
    left: unset;
  }
  .mobileImage img {
    width: 100%;
  }
  .innerContent h1 {
    font-size: 1.2em;
  }
}

@media (max-width: 1024px) {
  .images {
    display: none;
  }
  .desktopHeading {
    display: none;
  }
  .mobileHeading {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-top: 130px;
  }
  .content .description {
    display: none;
  }
  .desktopHeader h1 {
    display: none;
  }
  .mobileHeader h1 {
    line-height: 30px;
    letter-spacing: 0.28px;
    font-size: 18px;
  }
  .mobileHeader h1:first-child {
    font-family: "Montserrat-Medium";
    margin: 0;
  }
  .mobileHeader h1:last-child {
    font-family: "Montserrat-Bold";
  }
  .logoContainer {
    margin: 30px 0px;
  }
  .mobileImage {
    position: absolute;
    top: 49%;
    left: 27%;
  }
  .mobileImage img {
    width: 170px;
    height: 320px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 15px;
    object-fit: contain;
  }
  .heroWrapper {
    height: 540px;
  }
  .content {
    margin-top: 50px;
    justify-content: flex-end;
  }
  .mobileDescription .description {
    font-size: 16px;
    font-family: "Montserrat-Medium";
    line-height: 30px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.25px;
    width: 95%;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 50px;
  }
}
@media (min-width: 1025px) {
  .mobileHeading {
    display: none;
  }
  .mobileHeader {
    display: none;
  }
  .mobileDescription .description {
    display: none;
  }
}
@media (width: 320px) {
  .mobileImage {
    position: absolute;
    top: 61%;
    left: 27%;
  }
}

@media (width: 414px) {
  .mobileImage {
    position: absolute;
    top: 53%;
    left: 29%;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .mobileImage {
    position: absolute;
    top: 62%;
    left: 27%;
  }
}

.android {
  opacity: 0.5;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .mobileImage {
    position: absolute;
    top: 51%;
    left: 27%;
  }
  .mobileHeading {
    margin-top: 120px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .largestPhone img {
    max-width: 34%;
    max-height: 75%;
    min-width: 34%;
    min-height: 75%;
    object-fit: contain;
    position: relative;
    border-radius: 60px;
    left: 77%;
    top: 3%;
  }
  .content {
    max-width: 1000px;
  }
  .textContent h1 {
    font-size: 33px;
  }
  .description {
    line-height: 37px;
    width: 80%;
    font-size: 18px;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .largestPhone img {
    min-height: 55%;
    min-width: 40%;
    left: 62%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .largestPhone img {
    min-height: 49%;
    min-width: 35%;
    left: 77%;
  }
  .images {
    right: 19%;
  }
}

@media (min-width: 1440px) and (max-width: 1500px) {
  .largestPhone img {
    max-width: 40%;
    max-height: 46%;
    min-width: 41%;
    min-height: 32%;
    object-fit: contain;
    left: 68%;
    top: 3%;
  }
}

/* @media (width: 1024px) {
  .mobileHeader,
  .mobileDescription,
  .mobileImage {
    display: none;
  }
  .imagesContainer > div {
    width: 250px;
  }
} */

@media (width: 768px) {
  .mobileImage {
    top: 30%;
    left: 37%;
  }
  .mobileImage img {
    width: 200px;
    height: 400px;
  }
  .mobileHeading {
    margin-top: 200px;
  }
}

.logoContainer a {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: row;
}
.logoContainer svg {
  height: 550px;
  width: 550px;
}
.logoContainer {
  /* background-color: rgb(0, 0, 255, 0.5); */
  display: flex;
  background-color: rgb(0, 0, 0, 0.5);
  border: 8px solid white;
  flex-direction: row;
  transition: 0.6s;
  user-select: none;
}
.logoContainer:hover {
  background-color: rgb(0, 0, 25, 0.5);
  transition: 0.6s;
}
.logoInnerAbstract {
  /* width: 50px; */
  /* background-color: rgb(0, 255, 255, 0.5); */
  background-color: rgb(255, 255, 0, 0.1);
}

@media (max-width: 1680px) {
  .logoContainer {
    max-height: 500px;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .logoContainer a {
    width: 90%;
  }
}
