@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../../../assets/fonts/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../../../../assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../../../assets/fonts/Montserrat-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../../../../assets/fonts/Montserrat-Medium.otf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("../../../../../assets/fonts/OpenSans-Regular.ttf");
}

.sectionWrapper {
  height: 100%;
  color: #111;
  padding: 0px 20px;
  display: block;
  margin: 0 auto;
  background: rgb(15, 15, 15);
  background: linear-gradient(180deg, rgba(15, 15, 15, 1) 0%, rgba(46, 46, 46, 1) 100%);
  width: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maxWidth {
  max-width: 1440px;
  padding: 0 7%;
  margin: 0 auto;
  height: 100%;
  background-image: url("../../../../../assets/scissors.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center left;
  width: 100%;
}
.headerWrapper {
  padding: 80px 20px;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  background: rgb(51, 66, 91);
}
.bigBlueText {
  color: #fff;
  font-size: 2em;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.47px;
}
.prices {
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 25px;
  display: block;
  line-height: 28px;
  font-family: "OpenSans-Regular";
  letter-spacing: 0.25px;
  max-width: 540px;
}
.smallTextS {
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 25px;
  display: block;
  line-height: 28px;
  font-family: "OpenSans-Regular";
  letter-spacing: 0.25px;
  max-width: 540px;
}
@media (max-width: 1024px) {
  .prices {
    padding: 0 10px;
    font-size: 14px;
  }
  .smallTextS {
    padding: 0 20px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.contentWrapper {
  display: flex;
  flex-direction: row;
  margin: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
}

.combinePics {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .contentWrapper {
    display: flex;
    flex-direction: column;
  }
  .bigBlueText,
  .textContent {
    order: 2;
  }
  .prices {
    order: 3;
  }
  .smallTextS {
    order: 3;
  }
}

@media (max-width: 1024px) {
  .bigBlueText,
  .bigBlueTextBold {
    font-size: 28px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .sectionWrapper {
    padding: 0;
  }
  .bigBlueTextbold {
    font-size: 28px;
  }
  .prices {
    font-size: 18px;
    line-height: 28px;
  }
  .smallTextS {
    font-size: 14px;
    line-height: 28px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .prices {
    font-size: 18px;
  }
  .smallTextS {
    font-size: 16px;
  }
}

.firstRow {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  justify-content: center;
  align-items: center;
}
.secondRow {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  justify-content: center;
  align-items: center;
}

.thirdRow {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  justify-content: center;
  align-items: center;
}
.cardPrice {
  margin: 1% 0px;
  display: block;
  max-width: 300px;
  min-width: 300px;
}

.prices {
  border-radius: 22.5px;
  border: 0.5px solid #4a4a4a;
  padding: 3.5% 6%;
  max-width: 300px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  transition: 0.5s;
  user-select: none;
}

.prices:hover {
  background-color: rgb(255, 255, 255, 0.2);
  transition: 0.5s;
}

.horizontalRow .prices:hover {
  background-color: transparent;
}

.prices p {
  display: block;
  margin: 0;
  line-height: 45px;
}

.title {
  color: #fff;
  font-size: 1.7em;
  font-family: "Montserrat-Bold";
  margin-bottom: -1.5%;
  white-space: nowrap;
}

.titleSemiBold {
  color: #fff;
  font-size: 17px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: -1.5%;
}

.horizontalRow {
  text-align: center;
}

.horizontalRow .prices {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 0;
  max-width: 100%;
  min-width: 100%;
}
.horizontalRow .prices div {
  display: flex;
  justify-content: center;
}
.horizontalRow .prices div p {
  margin: 0 10px;
  margin-top: 1%;
}

.disclaim p {
  color: #918f8d;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 25px;
  display: block;
  line-height: 28px;
  font-family: "OpenSans-Regular";
  letter-spacing: 0.25px;
  border-top: 1px solid #4f4f4f;
  padding-top: 1%;
}

.pricesInner {
  border-radius: 22.5px;
  border: 0.5px solid #4a4a4a;
  padding: 1.5% 3%;
  max-width: 300px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  transition: 0.5s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0px auto;
}
.pricesInner p {
  margin: 0 !important;
}
@media (max-width: 1680px) {
  .prices p {
    font-size: 13.5px;
    font-family: "OpenSans-Regular";
  }
  .title {
    font-size: 1.2em;
  }
}

@media (max-width: 767px) {
  .prices,
  .pricesInner {
    order: 3;
    max-width: 240px;
    min-width: 240px;
  }
  .maxWidth {
    background-image: none;
  }
  .contentWrapper {
    padding-left: 5%;
  }
}
