@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../assets/fonts/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../assets/fonts/Montserrat-SemiBold.otf");
}

.headerWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  z-index: 999999;
  height: 90px;
}

.green {
  background-color: #12caaf;
}
.white {
  background-color: #fff;
  color: #12caaf;
}
.inlineLinks {
  display: flex;
}

.transparent {
  /* background-color: rgba(0, 0, 0, 0); */
  background-color: black;
}

.scrolledHeader {
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 1) 100%);
}
.scrolledHeader .innerHeader {
  /* margin-left: 60px; */
}
.innerHeader {
  height: 100%;
  color: #000;
  transition: all 0.5s ease;
  margin-left: 100px;
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.white .innerHeader {
  color: #12caaf;
}
.leftHeader {
  cursor: pointer;
}
.leftHeader,
.rightHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leftHeader > div:first-child {
  display: flex;
}
.leftHeader > div:first-child > span {
  text-transform: uppercase;
  font-size: 1.1em;
  letter-spacing: 1px;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.leftHeader svg {
  color: #000;
}
@media (min-width: 1025px) {
  .leftHeader svg {
    width: 140px;
    height: 90px;
  }
}
@media (max-width: 1024px) {
  .leftHeader svg {
    width: 55px;
    height: 55px;
  }
}
.white .leftHeader svg {
  color: #12caaf;
}
.scrolledHeader .leftHeader svg {
  color: #000;
}
.rightHeader {
  float: right;
}
.rightHeader:after {
  content: "";
  clear: both;
}
.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.linksContainer > div {
  margin-right: 60px;
}
.linksContainer a {
  font-size: 16px;
  padding-bottom: 2px;
  color: #fff;
  text-decoration: none;
  font-family: "Montserrat-Bold";
}
.white .linksContainer a {
  color: #12caaf;
}
.scrolledHeader .linksContainer a {
  color: #fff;
}
.activeLink a,
.linksContainer a:hover {
  border-bottom: 0.5px solid white;
}
.white .activeLink a,
.linksContainer a:hover {
  border-bottom: 0.5px solid #12caaf;
}
.scrolledHeader .activeLink a,
.linksContainer a:hover {
  border-bottom: 0.5px solid white !important;
}
.gettheApp span {
  background-color: #fff;
  color: #fff !important;
  height: 40px;
  width: 170px;
  max-width: 170px;
  border-radius: 35.5px;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  text-decoration: none;
  padding: 8px 25px;
  font-size: 16px;
}
/* burger icon */
.container {
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
  width: 24px;
  height: 2px;
  background-color: #fff;
  margin: 5px 0;
  transition: 0.55s;
}
.bar2 {
  position: relative;
  left: 5px;
}
.change .bar1 {
  /* -webkit-transform: rotate(-45deg) translate(-9px, 4px); */
  transform: rotate(-45deg) translate(-3px, 8px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  /* -webkit-transform: rotate(45deg) translate(-8px, -8px); */
  transform: rotate(45deg) translate(0px, -8px);
}
.container {
  display: none;
}
@media only screen and (max-width: 850px) {
  .innerHeader {
    margin: 0px 0px 0px 30px;
  }
  .linksContainer > div {
    margin-right: 30px;
  }
}
.linksContainer a {
  margin: 0;
}
@media only screen and (max-width: 600px) {
  .white .linksContainer a {
    color: #fff;
  }
  .white .leftHeader svg {
    color: #fff !important;
  }
  .innerHeader {
    padding-left: 15px;
    margin: 0px !important;
  }
  .container {
    display: initial;
  }
  .rightHeader {
    padding-right: 25px;
  }
  /* .headerWrapper {
    background: #fff;
  } */
  .linksContainer {
    position: absolute;
    width: 100%;
    top: 70px;
    right: 0;
    flex-direction: column;
  }
  .linksContainer > div {
    height: 0px;
    overflow: hidden;
    transition: all 0.5s ease;
    padding: 0px 0px;
    margin-right: 0px;
    background: #0e0e0e;
    text-align: center;
  }
  .dropdown > div {
    padding: 15px 0px;
    height: auto;
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .headerWrapper {
    height: 60px;
  }
  .leftHeader svg {
    height: 55px;
    width: 85px;
  }
  .linksContainer {
    top: 60px;
  }
}
@media (max-width: 767px) {
  .scrolledHeader {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 1) 100%);
  }
  .linksContainer > div {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 1) 100%);
  }
}
