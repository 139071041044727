svg, svg text, svg path, svg title, svg rect, svg tspan {
    font-family: 'Montserrat', sans-serif !important;
    /* font-weight:bold;
    letter-spacing:unset !important; */
}
/* @font-face {
	font-family: 'Helvetica Neue';
	src: url('./HelveticaNeue.ttf');
	src: url('./HelveticaNeue.ttf') format('truetype');
}
@font-face {
	font-family: 'Helvetica Neue SemiBold';
	src: url('./HelveticaNeueMed.ttf');
	src: url('./HelveticaNeueMed.ttf') format('truetype');
} */