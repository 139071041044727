@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../../assets/fonts/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../../../assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../../assets/fonts/Montserrat-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../../../assets/fonts/Montserrat-Medium.otf");
}

.planWrapper {
  background-color: #fff;
  height: 100%;
}

.sectionWrapper {
  height: 100%;
  color: #111;
  padding: 0px 20px;
  display: block;
  padding-top: 85px;
  margin: 0 auto;
  background: rgb(15, 15, 15);
  background: linear-gradient(180deg, rgba(15, 15, 15, 1) 0%, rgba(46, 46, 46, 1) 100%);
}
.headerWrapper {
  padding: 80px 20px;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  background: rgb(51, 66, 91);
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contentWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 280px;
}
img {
  object-fit: contain;
}
.wrapper {
  background-color: "#000";
  min-height: 300vh;
}
.bigBlueTextSBold {
  color: #fff;
  white-space: nowrap;
  font-size: 3.5em;
  letter-spacing: 1px;
  font-family: "Montserrat-Bold";
  line-height: 50px;
  margin-bottom: 35px;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
}
