/* @import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800"); */
@import "./assets//fonts/fonts.css";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  font-size: 18px;
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.website-wrapper {
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.button {
  cursor: pointer;
  border-radius: 30px;
  width: 340px;
  padding: 24px 0px;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

.button-white {
  width: 340px;
  background-color: #fff;
  color: rgb(21, 139, 202);
  padding: 20px 0px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 0;
  transition-property: background-color;
  transition-duration: 0.4s;
  box-sizing: padding-box;
}

.button-white-container {
  width: 340px;
  width: fit-content;
  padding: 4px;
  display: inline-block;
  border-radius: 34px;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(21, 140, 202, 1) 0%, rgba(21, 202, 185, 1) 100%);
}
.button-white-container:hover {
  background-image: linear-gradient(to bottom, #158bca, #1487c5, #1384bf, #1380ba, #127db5);
}
.button-white-container:hover .button {
  color: #fff;
  background-image: linear-gradient(to bottom, #158bca, #1487c5, #1384bf, #1380ba, #127db5);
}

#preloader {
  display: none;
}
.sectionHeading {
  font-size: 1.55em;
  color: rgb(21, 139, 202);
  text-align: left !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1.25em;
  line-height: 1.2em;
}

.sectionText {
  color: rgb(87, 87, 86);
  line-height: 1.45em;
}

.animateLeft,
.animateRight,
.animateTop,
.animateBottom {
  position: relative;
  opacity: 0;
}
.animate {
  transition: all 0.4s ease-out;
}
.visible.animate {
  transition-delay: 0.1s;
}
.visible.slowerTransition {
  transition-delay: 0.2s !important;
}
.visible.slowestTransition {
  transition-delay: 0.3s !important;
}
.visible.megaSlowTransition {
  transition-delay: 0.5s !important;
}
.visible.megaSlowerTransition {
  transition-delay: 0.6s !important;
}
.visible.megaSlowestTransition {
  transition-delay: 0.7s !important;
}
.animateTop {
  top: 30px;
}
.animateBottom {
  top: -100px;
}
.visible {
  left: 0px;
  top: 0px;
  right: 0px;
  opacity: 1;
}
@media only screen and (max-width: 800px) {
  .button {
    width: 270px;
    padding: 20px 0px;
    font-size: 14.5px;
  }
  .button-white-container {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
@media only screen and (max-width: 678px) {
  .sectionText {
    font-size: 0.8em;
    line-height: 1.3em;
  }
}
