@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../../../assets/fonts/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../../../../assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../../../assets/fonts/Montserrat-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../../../../assets/fonts/Montserrat-Medium.otf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("../../../../../assets/fonts/OpenSans-Regular.ttf");
}
.sectionWrapper {
  height: 100%;
  color: #111;
  background: #fff;
  padding: 0px 20px;
  display: block;
  margin: 0 auto;
  background: rgb(55, 56, 56);
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(15, 15, 15, 1) 100%);
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.maxWidth {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0 7%;
}
.headerWrapper {
  padding: 80px 20px;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  background: rgb(51, 66, 91);
}
.bigBlueText {
  color: #00b9bf;
  font-size: 2em;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.47px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contentWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 80px;
}

h2 {
  font-size: 30px;
  margin-bottom: 70px;
  color: #fff;
}
@media only screen and (max-width: 1000px) {
  .textContent {
    width: 100%;
    text-align: center;
  }
  .textInner h1,
  .description {
    margin-bottom: 20px !important;
  }
  .textContent > div {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1024px) {
  .contentWrapper {
    padding: 0px 10px;
  }
  .description {
    font-size: 24px;
    line-height: 24px;
  }
  .bigBlueText {
    font-size: 45px;
  }
  .contentWrapper {
    margin-top: 20px;
  }
  .sectionWrapper {
    padding: 0px;
  }
}

@media (max-width: 1024px) {
  .contentWrapper {
    display: flex;
    flex-direction: column;
  }
  .description {
    text-align: center;
  }
  .textContent {
    display: flex;
    align-items: center;
  }
  .bigBlueText {
    line-height: 46px;
  }
  .textContent {
    margin: 0;
  }
  .bigBlueText,
  .textContent {
    order: 2;
  }
  .smallText {
    order: 3;
  }
  .smallTextS {
    order: 3;
  }
}

@media (max-width: 1024px) {
  .bigBlueText,
  .bigBlueTextBold {
    font-size: 28px;
    line-height: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .sectionWrapper {
    padding: 0;
  }
  .imgContainer img {
    margin-top: 15%;
    width: 100%;
    height: 34%;
    max-width: 84%;
    max-height: 71%;
  }
  .textContent {
    margin-left: 0;
  }
  .bigBlueTextbold {
    font-size: 28px;
  }
  .smallText {
    font-size: 18px;
    line-height: 28px;
  }
  .smallTextS {
    font-size: 14px;
    line-height: 28px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .smallText {
    font-size: 18px;
  }
  .smallTextS {
    font-size: 16px;
  }
  .sectionWrapper {
    background-size: 100%;
  }
}

.title {
  color: #fff;
  font-size: 1.7em;
  font-family: "Montserrat-Bold";
  margin-bottom: 7%;
  white-space: nowrap;
}
.data,
a {
  color: #fff;
  font-size: 18px;
  display: block;
  line-height: 28px;
  font-family: "OpenSans-Regular";
  letter-spacing: 0.25px;
  text-decoration: none;
}

.working {
  width: 50%;
  padding: 3.5% 0%;
}

.address {
  width: 50%;
  text-align: right;
  border-radius: 22.5px;
  border: 0.5px solid #4a4a4a;
  padding: 3.5% 6%;
}
.working span,
.address span {
  display: block;
}

.partner .title {
  margin-bottom: 4%;
  margin-top: 10%;
}

@media (max-width: 767px) {
  .working {
    width: 100%;
    margin-bottom: 35px;
  }
  .data {
    font-size: 0.8em;
  }
  .data a {
    font-size: 1em;
  }
  .title {
    margin-bottom: 35px;
  }
  .address {
    width: 100%;
    padding: 10% 0;
  }
  .address .title,
  .address span,
  .address img {
    padding-right: 10%;
  }
  .address img {
    height: 70px !important;
    width: 160px !important;
    margin-top: 10px;
    object-fit: contain;
  }
}
.partner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
