@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../../assets/fonts/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../../../assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../../assets/fonts/Montserrat-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../../../assets/fonts/Montserrat-Medium.otf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("../../../../assets/fonts/OpenSans-Regular.ttf");
}

.sectionWrapper {
  height: auto;
  color: #111;
  padding: 0px 20px;
  display: block;
  margin: 0 auto;
  background: rgb(55, 56, 56);
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(15, 15, 15, 1) 100%);
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
@media (min-width: 1025px) {
  .sectionWrapper {
    min-height: 800px;
  }
}
.imageBios {
  background-image: url("../../../../assets/evgeni.png");
  height: 325px;
  min-width: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 22.5px;
  overflow: hidden;
}
.maxWidth {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  padding: 0 7%;
}
.headerWrapper {
  padding: 80px 20px;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  background: rgb(51, 66, 91);
}
.bigBlueText {
  color: #fff;
  white-space: nowrap;
  font-size: 30px;
  letter-spacing: 0.47px;
  line-height: 37px;
  font-family: "Montserrat-Medium";
}
.bigBlueTextBold {
  color: #fff;
  white-space: nowrap;
  font-size: 2em;
  font-family: "Montserrat-Bold";
  line-height: 50px;
}
.bigBlueTextSBold {
  color: #fff;
  white-space: nowrap;
  font-size: 1.2em;
  font-family: "Montserrat-SemiBold";
  line-height: 50px;
}
.smallText {
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 25px;
  display: block;
  line-height: 28px;
  font-family: "OpenSans-Regular";
}
.smallTextS {
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
  display: block;
  line-height: 28px;
  font-family: "OpenSans-Regular";
}

.poweredText {
  color: #fff;
  font-size: 16px;
  font-family: "Montserrat-Medium";
  letter-spacing: 0.25px;
  margin-top: 30px;
  margin-right: 5px;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contentWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.contentWrapper:last-child {
  margin-top: 20px;
}
.textContent {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
}

.imgContainer {
  /* margin: 0 auto; */
  max-width: 340px;
  width: 100%;
}
.imgContainer img {
  width: 100%;
  /* height:200px; */
}
.poweredDesktop {
  margin-top: -50px;
  margin-bottom: 50px;
}

.poweredImage {
  width: 60px;
  height: 60px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 30px;
  object-fit: contain;
}
.poweredImage:last-child {
  width: 50px;
  height: 50px;
  margin-left: 5px;
}
h2 {
  font-size: 30px;
  margin-bottom: 70px;
  color: #fff;
}

button {
  z-index: 9999;
  position: relative;
  width: 160px;
  max-height: 35px;
  min-height: 35px;
  padding: 0;
  line-height: 35px;
  font-family: "Montserrat-SemiBold";
  letter-spacing: 0.25px;
  border-radius: 25px;
  font-size: 16px;
  border: 0px;
  outline: 0;
  margin-top: 5px;
  margin-left: 0px;
  cursor: pointer;
}
.imageBiosM {
  display: none;
}
.btnClass {
  color: #fff;
  background-color: transparent;
  height: 35px;
  width: 160px;
  text-align: center;
  border-radius: 22.5px;
  line-height: 35px;
  border: 0.5px solid #878585;
  font-size: 16px;
  margin-top: 25px;
  cursor: pointer;
}

.hideMore {
  color: #fff;
  background-color: gray;
}

@media (max-width: 767px) {
  .sectionWrapper {
    padding: 0;
  }
  .textContent {
    margin: 0;
  }
  .maxWidth {
    padding: 0;
  }
  .contentWrapper {
    flex-direction: column;
  }
  .bigBlueTextBold {
    font-size: 1.2em;
  }
  .bigBlueTextSBold {
    font-size: 0.8em;
  }
  .smallText {
    font-size: 0.9em;
  }
  .btnClass {
    font-size: 14px;
    margin-top: 15px;
  }
  .imageBios {
    display: none;
  }
  .imageBiosM {
    background-image: url("../../../../assets/evgeni.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 22.5px;
    overflow: hidden;
    min-width: auto;
    width: 300px;
    height: 250px;
    object-fit: contain;
    display: block;
  }
  .smallText,
  .smallTextS {
    max-width: 320px;
  }
}
