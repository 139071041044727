@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../../../assets/fonts/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../../../../assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../../../assets/fonts/Montserrat-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../../../../assets/fonts/Montserrat-Medium.otf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("../../../../../assets/fonts/OpenSans-Regular.ttf");
}

.sectionWrapper {
  height: 100%;
  color: #111;
  background: #fff;
  padding: 0px 20px;
  display: block;
  margin: 0 auto;
  background: rgb(55, 56, 56);
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(15, 15, 15, 1) 100%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maxWidth {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 7%;
  height: 100%;
}
.headerWrapper {
  padding: 80px 20px;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  background: rgb(51, 66, 91);
}
.bigBlueText {
  color: #fff;
  font-size: 1.4em;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.47px;
  text-transform: none;
}
.smallText {
  color: #fff;
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 25px;
  display: block;
  line-height: 34px;
  font-family: "OpenSans-Regular";
  letter-spacing: 0.25px;
  text-align: left;
  text-transform: none;
}
.smallTextS {
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 25px;
  display: block;
  line-height: 28px;
  font-family: "OpenSans-Regular";
  letter-spacing: 0.25px;
  max-width: 540px;
}
@media (max-width: 1024px) {
  .smallText {
    padding: 0 10px;
    font-size: 14px;
  }
  .smallTextS {
    padding: 0 20px;
  }
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contentWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.textContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cardProduct {
  /* width: 310px;
  border: 1px solid #4a4a4a;
  padding: 10% 8%; */
  width: 440px;
  border: 1px solid #4a4a4a;
  padding: 6% 5%;
  border-top-left-radius: 22.5px;
  border-bottom-right-radius: 22.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.6s;
}

.cardHolder {
  display: flex;
  flex-direction: row;
  margin: 0 80px;
  user-select: none;
}
.imgStyling {
  background-image: url("../../../../../assets/5.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: inherit;
  height: 800px;
  width: 610px;
  margin-right: 30px;
  border-top-left-radius: 22.5px;
  border-top-right-radius: 22.5px;
  transition: filter 0.5s ease-in-out;
  -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%); /* FF 35+ */
}

.imgPflege {
  background-image: url("../../../../../assets/4.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: inherit;
  height: 800px;
  width: 610px;
  margin-right: 30px;
  border-top-left-radius: 22.5px;
  border-top-right-radius: 22.5px;
  transition: filter 0.5s ease-in-out;
  -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%); /* FF 35+ */
}

.imgPflege:hover,
.cardHolder:hover .imgPflege,
.imgStyling:hover,
.cardHolder:hover .imgStyling {
  -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%); /* FF 35+ */
}

.cardProduct:hover {
  background: rgb(0, 0, 255, 0.1);
  transition: 0.6s;
  border: 1px solid transparent;
}

.cardProduct:hover .imgStyling {
  -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%); /* FF 35+ */
}

@media (max-width: 1680px) {
  /* .imgStyling,
  .imgPflege {
    height: 500px;
    width: 375px;
    margin-bottom: 25px;
  } */
  /* .cardProduct {
    max-height: 460px;
  } */
  .cardHolder {
    margin: 35px 0;
  }
  .textContent {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .cardHolder {
    flex-direction: column;
  }
  .cardProduct {
    width: 270px;
    max-width: 270px;

    background: rgb(0, 0, 255, 0.1);
  }
  .imgStyling,
  .imgPflege {
    display: none;
  }
  .cardHolder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardHolder:first-child .cardProduct {
    background-image: url("../../../../../assets/5.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
  }
  .cardHolder:last-child .cardProduct {
    background-image: url("../../../../../assets/4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
  }
  .maxWidth,
  .sectionWrapper {
    padding: 0;
    margin: 0;
  }
  .description {
    text-align: left;
  }
  .smallText {
    line-height: 26px;
    background-color: rgba(28, 28, 28, 0.8);
    padding: 25px;
    border-top-left-radius: 10.5px;
    border-bottom-right-radius: 10.5px;
  }
}
